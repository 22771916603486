(function() {
    'use strict';

    angular.module('ionicAlert', [
        'ionic',
        'uabDefaultVariable',
        'uabEnvironment'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicAlert').service('AlertService', AlertService);

    AlertService.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        '$q',
        '$timeout'
    ];

    function AlertService(
        DefaultVariableService,
        EnvironmentService,
        $q,
        $timeout
    ) {
        var AlertService = this;

        AlertService.addMessage = addMessage;
        function addMessage(message, response) {
            var isPresent = false;
            for (var i = 0; i < AlertService.messages.length; i++) {
                if (AlertService.messages[i].message === message) {
                    isPresent = true;

                    AlertService.messages[i].count++;
                }
            }
            
            if (!isPresent) {
                message = {
                    count:   1,
                    message: message
                };

                AlertService.messages.push(message);
            }

            return AlertService.returnTrue(response);
        }

        AlertService.popMessages = popMessages;
        function popMessages(messages) {
            messages = messages ? messages : false;

            if (AlertService.messages.length !== 0) {
                var waitDuration = EnvironmentService.get('alertWaitDuration');
                waitDuration = DefaultVariableService.getInteger(
                    waitDuration,
                    500
                );

                return $timeout(
                    function() {
                        var message = AlertService.messages.shift();
                        if (DefaultVariableService.exist(message, 'message')) {
                            if (messages) {
                                messages += ', ';
                            } else {
                                messages = '';
                            }

                            messages += message.message;
                            if (message.count > 1) {
                                messages += ' (' + message.count + ')';
                            }
                        }

                        return AlertService.popMessages(messages);
                    },
                    waitDuration
                );
            }

            return $timeout(
                function() {
                    return messages;
                }
            );
        }

        AlertService.returnTrue = returnTrue;
        function returnTrue(alternative) {
            var deferred = $q.defer();

            var value = DefaultVariableService.get(
                alternative,
                true
            );

            deferred.resolve(value);

            return deferred.promise;
        }

        AlertService.reset = reset;
        function reset() {
            AlertService.messages = [];
        }

        AlertService.reset();

        return AlertService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicAlert').service('ErrorService', ErrorService);

    ErrorService.$inject = [
        'AlertService',
        'DefaultVariableService',
        'EnvironmentService',
        '$q'
    ];

    function ErrorService(
        AlertService,
        DefaultVariableService,
        EnvironmentService,
        $q
    ) {
        var ErrorService = this;

        ErrorService.addError = addError;
        function addError(message, response) {
            var promise = false;
            if (!DefaultVariableService.isDefined(message) || message.data === null) {
                promise = AlertService.addMessage('An unknown error occurred.');
            } else if (!DefaultVariableService.isDefined(message.data) || !DefaultVariableService.isDefined(message.data.errors)) {
                promise = AlertService.addMessage(message);
            } else {
                promise = AlertService.addMessage(message.data.errors);
            }

            return promise.then(
                function() {
                    return ErrorService.returnFalse(response);
                }
            );
        }

        ErrorService.addWarning = addWarning;
        function addWarning(message) {
            var debug = DefaultVariableService.get(
                EnvironmentService.get('debug'),
                false
            );

            if (debug) {
                console.log(message);
            }

            return ErrorService.returnFalse();
        }

        ErrorService.returnFalse = returnFalse;
        function returnFalse(alternative) {
            var deferred = $q.defer();

            var value = DefaultVariableService.get(
                alternative,
                false
            );

            deferred.resolve(value);

            return deferred.promise;
        }

        ErrorService.reset = reset;
        function reset() {
            ErrorService.errors = [];
        }

        ErrorService.init = init;
        function init() {
            ErrorService.reset();
        }

        ErrorService.init();

        return ErrorService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicAlert').controller('IonicAlertController', IonicAlertController);

    IonicAlertController.$inject = [
        'AlertService',
        'DefaultVariableService',
        '$ionicPopup',
        '$scope'
    ];

    function IonicAlertController(
        AlertService,
        DefaultVariableService,
        $ionicPopup,
        $scope
    ) {
        var IonicAlertController = this;

        $scope.$watch(
            function() {
                return DefaultVariableService.getArray(
                    AlertService.messages
                ).length;
            },
            function(length) {
                if (length !== 0) {
                    IonicAlertController.displayMessages();
                }
            }
        );

        IonicAlertController.addMessage = addMessage;
        function addMessage(message) {
            AlertService.addMessage(message);
        }

        IonicAlertController.displayMessages = displayMessages;
        function displayMessages() {
            return AlertService.popMessages().then(
                function(messages) {
                    if (messages) {
                        IonicAlertController.messages = IonicAlertController.messages.concat(messages);

                        IonicAlertController.hideMessages();

                        IonicAlertController.popup = $ionicPopup.show({
                            buttons: [
                                {
                                    text: 'Ok',
                                    type: 'button-positive accept-button'
                                }
                            ],
                            scope: $scope,
                            template: '<div>' + messages + '</div>',
                            title: 'ALERTS'
                        });
                    }
                }
            ).finally(
                function() {
                    IonicAlertController.messages = [];
                }
            );
        }

        IonicAlertController.hideMessages = hideMessages;
        function hideMessages() {
            IonicAlertController.popup.close();
        }

        IonicAlertController.reset = reset;
        function reset() {
            IonicAlertController.messages = [];

            IonicAlertController.popup = {
                close: DefaultVariableService.getPromise()
            };
        }

        IonicAlertController.init = init;
        function init() {
            IonicAlertController.reset();
        }

        IonicAlertController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicAlert').directive('ionicAlert', ionicAlert);

    function ionicAlert() {
        return {
            controller:   'IonicAlertController',
            controllerAs: 'ionicAlertCtrl',
            restrict:     'E'
        };
    }
})();